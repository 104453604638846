document.addEventListener('DOMContentLoaded', function() {

  // fix dynamic div size
  var form = document.getElementById('newsletter');
  var formnewsletter = document.getElementById('formnewsletter');
  formnewsletter.style.height = form.offsetHeight + 'px';

  form.addEventListener("submit", function(e) {

    e.preventDefault();

    var email = document.getElementById('inputEmail').value;
    var checkbox = document.getElementById('checkboxInputOverride').checked;
    var alertmail = document.getElementById('alertmail');
    var alertprivacy = document.getElementById('alertprivacy');
    var submit = true;

    if (!emailIsValid(email) ) {
      alertmail.classList.remove("hidden");
      submit = false;
    };

    if (!checkbox) {
      alertprivacy.classList.remove("hidden");
      submit = false;
    };

    if (submit) {
      form.classList.add("hidden");
      submitEmail(email);
    };

    return submit;

  });

}, false);

function emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function submitEmail(email) {
  
  var thankyou = document.getElementById('thankyou');
  var alreadyregistered = document.getElementById('alreadyregistered');
  var submiterror = document.getElementById('submiterror');

  fetch('./.netlify/functions/form-submit', {
      method: 'post',
      body: JSON.stringify({
        email: email
      })
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
        if (data.statusCode == 200) {
          thankyou.classList.remove('hidden');
        } else if (data.statusCode == 400 && data.errorDesc == "Member Exists") {
          alreadyregistered.classList.remove('hidden');
        } else {
          submiterror.classList.remove('hidden');
        };
    }).catch(error => {
        submiterror.classList.remove('hidden');
    }); 
};